import { atom } from 'recoil';

export const firstGridState = atom({
  key: 'firstGridState',
  default: ''
});

export const secondGridState = atom({
  key: 'secondGridState',
  default: ''
});

export const colorState = atom({
  key: 'colorState',
  default: null
});

export const betState = atom({
  key: 'betState',
  default: []
});

export const winningNumberState = atom({
  key: 'winningNumberState',
  default: ''
});

export const winnerState = atom({
  key: 'winnerState',
  default: ''
});
