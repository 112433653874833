const LShape = () => {
	return (
		<svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 52.8 55.8" >
			<g transform="translate(0.000000,70.000000) scale(0.100000,-0.100000)">
			</g>
			<g>
				<path fill="currentColor" d="M8.9,55.8c-1.5,0-3-0.3-4.3-1c-1.4-0.7-2.5-1.7-3.3-3C0.4,50.5,0,48.9,0,47c0-1.9,0.4-3.6,1.3-5.2
		C2.2,40.2,3.5,39,5.1,38c1.6-1,3.6-1.5,5.8-1.5c1.3,0,2.5,0.2,3.7,0.5c1.2,0.3,2.3,0.7,3.3,1.2l8-17.4c-2.1-0.7-3.9-1.6-5.4-2.6
		c-1.5-1-2.8-2.1-3.8-3.1c-1-1-1.7-1.9-2.3-2.5L18.2,9c0.4,0.7,1,1.5,1.9,2.4c0.8,0.9,1.9,1.9,3.2,2.8c1.3,0.9,2.9,1.7,4.8,2.2
		l3.4-7.3c1.2-2.6,2.7-4.8,4.5-6.5c1.8-1.7,4.1-2.6,7-2.6c3.3,0,5.7,0.9,7.1,2.6c1.4,1.7,2.1,3.7,2.1,5.9c0,2.4-0.8,4.6-2.3,6.7
		c-1.5,2.1-3.7,3.8-6.4,5c-2.7,1.3-5.9,1.9-9.5,1.9c-1,0-1.9,0-2.9-0.2l-8.8,19.1c1.4,1.2,2.8,2.5,4.3,4.1c1.5,1.5,3.1,2.8,5,3.9
		c1.8,1.1,4,1.6,6.5,1.6c1.5,0,3.3-0.3,5.4-0.9c2.1-0.6,4.4-1.7,6.9-3.4l2.5,4c-2.5,1.6-5,2.9-7.6,3.9c-2.6,1-5.2,1.5-7.7,1.5
		c-2.9,0-5.4-0.5-7.4-1.6c-2-1.1-3.9-2.4-5.4-3.9c-1.6-1.5-3.1-3-4.5-4.4l-1.4,3c-1,2.1-2.3,3.8-4,5C13,55.2,11.1,55.8,8.9,55.8z
		 M5.5,46.9c0,1.3,0.4,2.3,1.1,2.9c0.8,0.7,1.6,1,2.5,1c0.7,0,1.5-0.3,2.4-0.8c0.9-0.6,1.7-1.6,2.4-3.1l1.8-4
		c-0.7-0.4-1.5-0.7-2.3-1s-1.6-0.4-2.5-0.4c-1.9,0-3.4,0.6-4.2,1.7C6,44.3,5.5,45.6,5.5,46.9z M36,11.2l-2.8,6h0.6
		c3.9,0,7-0.8,9.3-2.3c2.3-1.5,3.4-3.4,3.4-5.8c0-1.2-0.3-2.2-1-3C44.9,5.4,43.9,5,42.5,5c-1.5,0-2.7,0.5-3.7,1.6
		C37.9,7.7,36.9,9.3,36,11.2z"/>
			</g>
		</svg>
	)
}

export default LShape;
