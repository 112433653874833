import styled, { css } from 'styled-components';
import HeadingGfx from '../../images/HeadingShape.svg';
import media from '../../theme/media';

const ShapeCSS = css`
  &:before, &:after {
    content: '';
    position: absolute;
    top: unset;
    bottom: -30px;
    max-width: 100px;
    left: 50%;
    width: 100%;
    background-image: url(${HeadingGfx});
    background-repeat: no-repeat;
    background-size: contain;

    ${(media.moreThan('tablet'))} {
      top: 50%;
      bottom: unset;
      max-width: 200px;
      left: unset;
    }
  }

  &:before {
    transform: translateX(-100%) rotate(-180deg);

    ${(media.moreThan('tablet'))} {
      transform: translate(calc(-100% - 20px), -50%) rotate(-180deg);
    }
  }

  &:after {
    transform: translateX(0%);

    ${(media.moreThan('tablet'))} {
      transform: translate(20px, -50%);
    }
  }
`;

const Typo = {
  H1: styled.h1`
    margin: ${(props) => props.marginTop || '0'} 0 ${(props) => props.marginBottom || '0'} 0;
    font-family:${(props) => props.theme.fonts.types.SECONDARY};
    color: rgb(${(props) => props.color || props.theme.colors.WHITE});
    font-weight: 600;
    font-size: ${(props) => props.theme.fonts.sizes.h1 * 0.6}rem;
    text-align: ${(props) => props.textAlign || 'center'};
    text-transform: uppercase;
    position: relative;
    margin-bottom: 60px;
    
    ${(media.moreThan('mobile'))} {
      font-size: ${(props) => props.theme.fonts.sizes.h1 * 0.8}rem;
      margin-bottom: 60px;

    }

    ${(media.moreThan('tablet'))} {
      font-size: ${(props) => props.theme.fonts.sizes.h1}rem;
      margin-bottom: 0px;
    }

    ${ShapeCSS}
    
    &:before, &:after {
      height: 26px;

    ${(media.moreThan('tablet'))} {
        height: 50px;
      }
    }

  `,
  H2: styled.h2`
    margin: ${(props) => props.marginTop || '0'} 0 ${(props) => props.marginBottom || '0'} 0;
    font-family:${(props) => props.theme.fonts.types.SECONDARY};
    color: rgb(${(props) => props.color || props.theme.colors.WHITE});
    font-weight: 600;
    font-size: ${(props) => props.theme.fonts.sizes.h2 * 0.7}rem;
    text-align: ${(props) => props.textAlign || 'center'};
    text-transform: uppercase;
    position: relative;
    margin-bottom: 40px;

    ${(media.moreThan('tablet'))} {
      font-size: ${(props) => props.theme.fonts.sizes.h2}rem;
      margin-bottom: 0;
    }

    ${ShapeCSS}
    
    &:before, &:after {
      height: 25px;

      ${(media.moreThan('tablet'))} {
        height: 50px;
      }
    }
  `,
  H3: styled.h3`
    margin: ${(props) => props.marginTop || '0'} 0 ${(props) => props.marginBottom || '0'} 0;
    font-family:${(props) => props.theme.fonts.types.SECONDARY};
    color: rgb(${(props) => props.color || props.theme.colors.WHITE});
    font-weight: 600;
    font-size: ${(props) => props.theme.fonts.sizes.h3}rem;
    text-align: ${(props) => props.textAlign || 'center'};
    text-transform: uppercase;
    position: relative;
    margin-bottom: 40px;

    ${ShapeCSS}
    
    &:before, &:after {
      height: 25px;
      ${(media.moreThan('tablet'))} {
        height: 25px;
      }
    }

    ${(media.moreThan('tablet'))} {
      margin-bottom: 0;
    }
  `,
  H4: styled.h4`
    margin: ${(props) => props.marginTop || '0'} 0 ${(props) => props.marginBottom || '0'} 0;
    font-family:${(props) => props.theme.fonts.types.SECONDARY};
    color: rgb(${(props) => props.color || props.theme.colors.WHITE});
    font-weight: 600;
    font-size: ${(props) => props.theme.fonts.sizes.h4}rem;
    text-align: ${(props) => props.textAlign || 'center'};
    text-transform: uppercase;
    position: relative;
    margin-bottom: 40px;

    ${ShapeCSS}
    
    &:before, &:after {
      height: 20px;
    }

    ${(media.moreThan('tablet'))} {
      margin-bottom: 0;
    }
  `,
  H5: styled.h5`
    margin: ${(props) => props.marginTop || '0'} 0 ${(props) => props.marginBottom || '0'} 0;
    font-family:${(props) => props.theme.fonts.types.SECONDARY};
    color: rgb(${(props) => props.color || props.theme.colors.WHITE});
    font-weight: 600;
    font-size: ${(props) => props.theme.fonts.sizes.h5}rem;
    text-align: ${(props) => props.textAlign || 'center'};
    text-transform: uppercase;
  `,
  Xl: styled.p`
  margin: 0 0 ${(props) => props.marginBottom || '0'} 0;
  font-family:${(props) => props.theme.fonts.types.PRIMARY};
  color: rgb(${(props) => props.color || props.theme.colors.YELLOW});
  font-weight: ${(props) => props.fontWeight || '400'};
  font-size: ${(props) => props.theme.fonts.sizes.xl}rem;
  text-align: ${(props) => props.textAlign || 'left'};
  text-transform: ${(props) => props.transform || 'none'};
`,
  Lg: styled.p`
    margin: 0 0 ${(props) => props.marginBottom || '0'} 0;
    font-family:${(props) => props.theme.fonts.types.PRIMARY};
    color: rgb(${(props) => props.color || props.theme.colors.WHITE});
    font-weight: ${(props) => props.fontWeight || '400'};
    font-size: ${(props) => props.theme.fonts.sizes.lg}rem;
    text-align: ${(props) => props.textAlign || 'left'};
    text-transform: ${(props) => props.transform || 'none'};
  `,
  Md: styled.p`
    margin: 0 0 ${(props) => props.marginBottom || '0'} 0;
    font-family:${(props) => props.theme.fonts.types.PRIMARY};
    color: rgb(${(props) => props.color || props.theme.colors.WHITE});
    font-weight: ${(props) => props.fontWeight || '400'};
    font-size: ${(props) => props.theme.fonts.sizes.md}rem;
    text-align: ${(props) => props.textAlign || 'left'};
    text-transform: ${(props) => props.transform || 'none'};
    line-height: ${(props) => props.lineHeight}
  `,
  Sm: styled.p`
    margin: 0 0 ${(props) => props.marginBottom || '0'} 0;
    font-family:${(props) => props.theme.fonts.types.PRIMARY};
    color: rgb(${(props) => props.color || props.theme.colors.WHITE});
    font-weight: ${(props) => props.fontWeight || '400'};
    font-size: ${(props) => props.theme.fonts.sizes.sm}rem;
    text-align: ${(props) => props.textAlign || 'left'};
    text-transform: ${(props) => props.transform || 'none'};
  `,
  Info: styled.p`
  margin: 0 0 ${(props) => props.marginBottom || '0'} 0;
  font-family:${(props) => props.theme.fonts.types.PRIMARY};
  color: rgb(${(props) => props.color || props.theme.colors.GREEN_LIGHT});
  font-weight: ${(props) => props.fontWeight || '400'};
  font-size: ${(props) => props.theme.fonts.sizes.sm}rem;
  text-align: ${(props) => props.textAlign || 'left'};
  text-transform: ${(props) => props.transform || 'none'};
`,
  BigLink: styled.a`
  margin-bottom: 30px;
  font-size: 2rem;
  font-weight: 700;
  color: rgb(${(props) => props.theme.colors.YELLOW});
  position: relative;
  
  &:after {
    content: '';
    position: absolute;
    bottom: -6px;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: rgb(${(props) => props.theme.colors.YELLOW});
  }
  `
}

export default Typo;
