import styled from 'styled-components';
import { AiOutlineClose } from 'react-icons/ai';
import LineShape from '../../images/LineShape.svg';
import media from '../../theme/media';
import Pattern from '../../images/felt-pattern.jpg';

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(${(props) => props.theme.colors.BLACK}, 0.8);
  z-index: 99;
`;

const StyledModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-flex;
  width: 90%;
  justify-content: center;

  &:before, &:after {
    content: '';
    position: absolute;
    max-width: 390px;
    width: 90%;
    height: 25px;
    background-image: url(${LineShape});
    background-repeat: no-repeat;
    z-index: 1;
    display: ${(props) => props.spinning ? 'none' : 'block'};

  }
  ${(media.moreThan('tablet'))} {
    width: 50%;
  }

  div {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url(${Pattern});
      opacity: 0.1;
      pointer-events: none;
    }
  }

  &:before {
    top: 10px;
    left: 50%;
    transform: translateX(-50%) rotate(180deg);
  }

  &:after {
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
  }

  span {
    position: relative;
    width: 100%;
    max-width: 550px;
    display: flex;
    justify-content: center;
  }

  button {
    margin-right: 0 !important;
  }
`;

const Close = styled(AiOutlineClose)`
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 2rem;
  z-index: 3;
`;

const Modal = ({ children, handleModal, spinning }) => {
  return (
    <ModalWrapper onClick={() => handleModal(false)}>
      <StyledModal onClick={e => e.stopPropagation()} spinning={spinning}>
        <span>
          {!spinning && <Close onClick={() => handleModal(false)} />}
          {children}
        </span>
      </StyledModal>
    </ModalWrapper>
  )
}

export default Modal;
