import { HashConnect, HashConnectTypes, MessageTypes } from "hashconnect";
import LuckyToken from '../images/YELLOW_rev0.png';

export let pairingData: MessageTypes.ApprovePairing | null = null;
export const pairedAccountId = () => {
  if (pairingData && pairingData.accountIds[0]) {
    return pairingData.accountIds[0];
  }

  return null;
}

export const hashconnect = new HashConnect();
hashconnect.pairingEvent.on((data) => {
  pairingData = data;
});

export const hcInitPromise = new Promise(async (resolve) => {
  const appMetadata: HashConnectTypes.AppMetadata = {
    name: 'Lucky HTS',
    description: 'Lucky token',
    icon: LuckyToken,
    url: window.location.origin
  };
  const initResult = await hashconnect.init(appMetadata, "mainnet", true);
  if (initResult.savedPairings) {
    pairingData = initResult.savedPairings[initResult.savedPairings.length - 1];
  }
  resolve(initResult);
});

export const getProvider = async () => {
  await hcInitPromise;

  if (!pairingData) {
    throw new Error("Pairing data is not set");
  }

  const accId = pairedAccountId();
  if (!accId) {
    throw new Error("No paired account");
  }

  const provider = hashconnect.getProvider("mainnet", pairingData.topic, accId);
  return provider;
}

export const getSigner = async () => {
  const provider = await getProvider();
  const signer = hashconnect.getSigner(provider);
  return signer;
}

