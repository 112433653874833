import { AccountId, TransactionId, TransactionReceiptQuery, TransactionRecordQuery, TransferTransaction } from '@hashgraph/sdk'
import sha256 from "crypto-js/sha256.js";
import Hex from "crypto-js/enc-hex.js";

const MIRROR_NODE_URL = 'https://mainnet-public.mirrornode.hedera.com/api/v1';

const LuckyTokenId = '0.0.1109951';
// const LuckyTokenId = '0.0.47686761';
const LuckyTreasuryId = '0.0.1105875';
// const LuckyTreasuryId = '0.0.46806455';

export const sendLucky = async (sender, signer) => {
  let tokenTransfer = await new TransferTransaction()
    .addTokenTransfer(AccountId.fromString(LuckyTokenId), sender, -1)
    .addTokenTransfer(AccountId.fromString(LuckyTokenId), LuckyTreasuryId, 1)
    .setTransactionMemo("Lucky_Token_Spend_7777777")
    .freezeWithSigner(signer);

  const res = await tokenTransfer.executeWithSigner(signer);
  console.log("The transaction was successfull: ", res);

  return res ? true : false;
}

export const spinTheWheel = async (sender, bets, signer, wager) => {
  const memo = bets[0] === '0' ? '00' : bets[0] < 10 && bets[0][0] !== '0' ? '0' + bets[0] : bets[0];

  const balance = await getBalance(sender);

  if (balance <= wager) {
    return [false, 'Not enough $LUCKY tokens. <br/> <a href="https://www.saucerswap.finance/swap/0.0.1062664/0.0.1109951" target="_blank" rel="norefferer">Get more!</a>'];
  }

  let tokenTransfer = await new TransferTransaction()
    .addTokenTransfer(AccountId.fromString(LuckyTokenId), sender, -wager)
    .addTokenTransfer(AccountId.fromString(LuckyTokenId), LuckyTreasuryId, wager)
    .setTransactionMemo(`LuckyRouletteGen0,${memo == '000' ? '00' : memo.toString()}`)
    .freezeWithSigner(signer);
  tokenTransfer = await tokenTransfer.signWithSigner(signer);

  const res = await tokenTransfer.executeWithSigner(signer);
  // console.log("The transaction was successfull: ", res);
  // const record = await res.getRecordWithSigner(signer)
  // console.log(record)


  // setTimeout(async () => {
  //   console.log(res.transactionId)
  //   await getTxnRecord(res.transactionId, signer)
  // }, 10000)

  return res ? new Promise((resolve, reject) => {
    let consensus;
    let rouletteValue;

    setTimeout(async () => {
      consensus = await getTxnInfo(res.transactionId)
      if (consensus) {
        rouletteValue = determine_roulette_value(consensus.transactions[0].consensus_timestamp, false);
        resolve(rouletteValue)
      } else {
        reject([false, 'Something went wrong. Please try again!'])
      }
    }, 7000)
  }).then((done) => {
    return done ? [done, memo.toString()] : [false, 'Something went wrong. Please try again!'];
  }).catch((error) => {
    return error
  }) : [false, 'Something went wrong. Please try again!']
}

const getTxnRecord = async (transactionId, signer) => {
  console.log("txReceipt transactionId", transactionId)

  let tokenTransferReceipt = await new TransactionRecordQuery()
    .setTransactionId(transactionId)
    .executeWithSigner(signer);

  console.log(tokenTransferReceipt)
  return tokenTransferReceipt
}

const getTxnInfo = async (transactionId) => {
  const txnId = `${transactionId.split("@")[0]}-${transactionId.split("@")[1].replace(".", "-")}`;
  let getConsensus = await fetch(`${MIRROR_NODE_URL}/transactions/${txnId}`)
    .then(res => {
      if (res.ok) {
        return res.json();
      } else {
        return getTxnInfo(transactionId);
      }
    })
  const response = await getConsensus;
  return response;
}

export const getBalance = async (accountId) => {
  if (!accountId) return;
  const res = await fetch(`${MIRROR_NODE_URL}/tokens/${LuckyTokenId}/balances?account.id=${accountId}`)

  const data = await res.json();

  return data?.balances[0]?.balance;
}

const determine_roulette_value = function (consensus_timestamp, debug) {
  const hash = sha256(consensus_timestamp).toString(Hex).slice(0, 10);
  const integer_hash = parseInt(hash, 16);
  const roulette_number = integer_hash % 100;
  if (debug) {
    console.log(
      consensus_timestamp,
      ", ",
      hash,
      ", ",
      integer_hash,
      ", ",
      roulette_number
    );
  }
  return roulette_number.toString();
};
