const types = {
  PRIMARY: "'Lato', 'sans-serif'",
  SECONDARY: "'Noto Serif', 'sans-serif'",
}

const sizes = {
  h1: '4',
  h2: '2.5',
  h3: '2',
  h4: '1.5',
  h5: '1.5',
  xl: '2',
  lg: '1.2',
  md: '1.1',
  sm: '0.95',
}

const fonts = {
  types,
  sizes
}

export default fonts;
