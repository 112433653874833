import colors from './colors';
import media from './media';
import fonts from './fonts';

const theme = {
  colors,
  media,
  fonts
}

export default theme;
