import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { useCallback, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { hamburgerState } from '../../atoms/globalAtoms';
import Hamburger from '../Hamburger';
import media from '../../theme/media';
import NavDecor from '../../images/NavDecor.svg';

const NavigationWrapper = styled.nav`
  order: 2;

  ${(media.moreThan('tablet'))} {
    order: 1;
  }

  ul {
    list-style-type: none;
    align-items: center;
    gap: 20px;
    margin: 0;
    display: flex;
    padding: 0;

    li {
      display: none;
      ${(media.moreThan('tablet'))} {
        display: block;
      }
    }

    ${(props) => props.isToggled && css`
      background-color: rgb(${(props) => props.theme.colors.GREEN});
      flex-direction: column;
      justify-content: center;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      font-size: 2rem;
      gap: 30px;
      padding: 0;

      li {
        display: block !important;
      }

      div.hamburger {
        position: absolute;
        top: 37px;
        right: 20px;
      }
    `}
  }
`;

const StyledLink = styled(NavLink)`
  color: rgb(${(props) => props.theme.colors.WHITE});
  font-family: ${(props) => props.theme.fonts.types.SECONDARY};
  position: relative;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  white-space: nowrap;

  &:before{
    content: '';
    position: absolute;
    height: 15px;
    width: 0;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    transition: all .15s ease-out;
    background-image: url(${NavDecor});
    background-repeat: no-repeat;
    background-size: contain;
    max-width: 105px;
  }

  &:hover, &.active {
    color: rgb(${(props) => props.theme.colors.YELLOW});

    &:before {
      width: 100%;
    }
  }
`;

const Navigation = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const setHamburger = useSetRecoilState(hamburgerState);

  const leaveMenu = useCallback(() => {
    setToggleMenu(false)
    setHamburger(false)
  }, [setToggleMenu, setHamburger])

  return (
    <NavigationWrapper isToggled={toggleMenu}>

      <ul>
        <li onClick={leaveMenu}><StyledLink activeclassname="active" to="/greenpaper">Greenpaper</StyledLink></li>
        <li onClick={leaveMenu}><StyledLink activeclassname="active" to="/get-lucky">Get Lucky</StyledLink></li>
        <li onClick={leaveMenu}><StyledLink activeclassname="active" to="/roulette">Roulette</StyledLink></li>
        <Hamburger handleClick={() => setToggleMenu(!toggleMenu)} />
      </ul>
    </NavigationWrapper>
  )
}

export default Navigation;
