const colors = {
  WHITE: '255,255,255',
  BLACK: '31,31,31',
  RED: '196, 32, 29',
  RED_DARK: '128,20,18',
  GREEN: '65, 124, 37',
  GREEN_DARK: '19,127,0',
  GREEN_LIGHT: '95,182,52',
  YELLOW: '244,218,64',
  GREY: '33, 34, 32'
}

export default colors;
