import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { accountBalanceState, accountIdState } from '../../atoms/globalAtoms';
import theme from '../../theme';
import LuckyToken from '../../images/LuckyToken.svg';
import LShape from '../../images/LShape';
import Typo from '../Typo';
import { useState } from 'react';
import Modal from '../Modal';
import BalancePopup from './popup';
import PairingPopup from '../Pairing/PairingPopup';
import media from '../../theme/media';

const BalanceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;

  &:hover {
    p:before {
      width: 100%;

    }
  }

  img {
    min-width: 15px;
    max-width: 20px !important;
    width: 100%;

    ${(media.moreThan('mobile'))} {
      max-width: 30px !important;
    } 
  }

  svg {
    max-width: 12px;
    width: 100%;
    margin-right: 5px;

    ${(media.moreThan('mobile'))} {
      max-width: 15px;
    } 
  }

  p {
    white-space: nowrap;
    margin-right: 10px;
    font-size: ${(props) => props.theme.fonts.sizes.md * 0.8}rem;
    position: relative;
    
    ${(media.moreThan('mobile'))} {
      font-size: ${(props) => props.theme.fonts.sizes.md}rem;
    } 
    
    &:before {
      content: '';
      position: absolute;
      width: 0%;
      height: 3px;
      left: 0;
      bottom: -5px;
      background-color: rgb(${(props) => props.theme.colors.YELLOW});
      transition: all .15s ease-out;
    }
  }
`;

const Balance = () => {
  const balance = useRecoilValue(accountBalanceState);
  const [modalOpen, setModalOpen] = useState(false);
  const accountId = useRecoilValue(accountIdState);

  return (
    <>
      <BalanceWrapper onClick={() => setModalOpen(true)}>
        <Typo.Md fontWeight="700" color={theme.colors.YELLOW}>

          {accountId ? <><LShape />{balance?.toLocaleString()}</> : 'Connect'}
        </Typo.Md>
        <img src={LuckyToken} alt="Lucky Token" />
      </BalanceWrapper>
      {modalOpen &&
        <Modal handleModal={setModalOpen}>
          {accountId ? <BalancePopup /> : <PairingPopup />}
        </Modal>
      }
    </>
  )
}

export default Balance;
