import { Helmet } from 'react-helmet-async';
import Section from '../components/Section';
import Typo from '../components/Typo';
import HowToGetLucky from '../images/HowToGetLucky.jpg';
import styled from 'styled-components';
import { SeperatorDown, SeperatorUp } from '../components/Seperator';

const ImageWrapper = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  img {
    width: 100%;
  }
`;

const GetLucky = () => {
  return (
    <main>
      <Helmet>
        <title>Lucky Token | Get Lucky</title>
      </Helmet>
      <Section>
        <SeperatorUp />
        <Typo.H1>Get Lucky</Typo.H1>
        <ImageWrapper>
          <Typo.BigLink href="https://www.saucerswap.finance/swap/0.0.1062664/0.0.1109951" target="_blank" rel="norefferer">Go to SaucerSwap</Typo.BigLink>
          <img loading='lazy' src={HowToGetLucky} alt="How to get lucky tokens" />
        </ImageWrapper>
        <SeperatorDown />
      </Section>
    </main>
  )
}

export default GetLucky;
