import styled from 'styled-components';
import Confetti from 'react-confetti';
import { useEffect, useState } from 'react';
import ConfettiPopWav from '../../sounds/pop.mp3';
import { useRecoilValue } from 'recoil';
import { audioState } from '../../atoms/globalAtoms';

const ConfettiWrapper = styled(Confetti)`
  position: fixed;
  top: 0;
  left: -0px;
  z-index: 999 !important;
`;


const ConfettiRain = ({ isActive }) => {
  const [active, setActive] = useState(isActive);
  const [audio] = useState([new Audio(ConfettiPopWav)])
  const sound = useRecoilValue(audioState);

  useEffect(() => {
    setTimeout(() => {
      setActive(isActive)
    }, sound ? 3000 : 0)
  }, [isActive, setActive, sound])

  useEffect(() => {
    if (active && sound) audio[0].play()
  }, [active, audio, sound])

  return (
    active &&
    <ConfettiWrapper
      width={window.innerWidth}
      height={window.innerHeight}
      numberOfPieces={3000}
      recycle={false}
      run={active}
      onConfettiComplete={() => setActive(false)}
    />
  )
}

export default ConfettiRain;
