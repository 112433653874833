import styled, { css } from 'styled-components';
import React, { useCallback } from 'react';
import media from '../../theme/media';
import { useRecoilState } from 'recoil';
import { hamburgerState } from '../../atoms/globalAtoms';

const HamburgerWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 30px;
  height: 18px;
  cursor: pointer;
  
  ${(media.moreThan('tablet'))} {
    display: none;
  }

  ${(props) => props.isActive && css`
    span:nth-of-type(1){
      top: 50%;
      transform: translateY(-50%) rotate(45deg);
    }
    span:nth-of-type(2){
      transform:  translateX(100%);
      opacity: 0;
    }
    span:nth-of-type(3){
      top: 50%;
      transform: translateY(-50%) rotate(-45deg);
    }
  `}
`;

const HamburgerLine = styled.span`
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: rgb(${(props) => props.theme.colors.WHITE});
  transition: all .15s ease-out;

  &:nth-of-type(1){
    top: 0px;
  }

  &:nth-of-type(2){
    top: 50%;
    transform: translateY(-50%);
  }

  &:nth-of-type(3){
    top: calc(100% - 3px);
  }
`;

const Hamburger = ({ handleClick }) => {
  const [active, setActive] = useRecoilState(hamburgerState);

  const toggleMenu = useCallback(() => {
    setActive(!active)
    handleClick()
  }, [setActive, active, handleClick])

  return (
    <HamburgerWrapper isActive={active} className="hamburger" onClick={toggleMenu}>
      <HamburgerLine />
      <HamburgerLine />
      <HamburgerLine />
    </HamburgerWrapper>
  )
}

export default Hamburger;
