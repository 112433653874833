import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { accountIdState, audioState } from '../../atoms/globalAtoms';
import { betState, colorState, firstGridState, secondGridState, winnerState, winningNumberState } from '../../atoms/rouletteAtom';
import { GRID_SIZE } from '../../constants/grid';
import LuckyToken from '../../images/LuckyToken.svg';
import media from '../../theme/media';
import Button from '../Button';
import Modal from '../Modal';
import Typo from '../Typo';
import RouletteSpin from '../../images/roulettespin.gif';
import { getSigner } from '../../utils/hashconnect.ts';
import { spinTheWheel } from '../../utils/hedera';
import { AiOutlineClose } from 'react-icons/ai';
import SpinningWav from '../../sounds/spinning.mp3';
import SpinStopWav from '../../sounds/spin_stop.mp3';
import ChipsWav from '../../sounds/chip.mp3';
import WinWav from '../../sounds/win.mp3';
import Pairing from '../Pairing';

const BettingRoundsWrapper = styled.div`
  max-width: 230px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 23px;
  align-items: center;

  ${(media.moreThan('tablet'))} {
    margin: 0;
  }
`;

const BettingRoundsContainer = styled.div`
  display: inline-grid;
  grid-template-columns: repeat(4, 1fr);
  border: 3px solid rgb(${(props) => props.theme.colors.WHITE});
  border-bottom: none;
  background-color: rgb(${(props) => props.theme.colors.GREEN});
  color: rgb(${(props) => props.theme.colors.WHITE});
`;

const BettingRound = styled.div`
  font-family: ${(props) => props.theme.fonts.types.SECONDARY};
  font-size: 1.9rem;
  font-weight: 700;
  line-height: 1;
  margin: 0;
  display: grid;
  place-items: center;
  width: ${GRID_SIZE}px;
  height: ${GRID_SIZE}px;
  border-bottom: 3px solid rgb(${(props) => props.theme.colors.WHITE});
  transition: all .15s ease-in;
  text-transform: uppercase;
  position: relative;
  cursor: pointer;

  &:first-of-type {
    cursor: default;
    font-size: 1.5rem;

    svg {
      height: calc(100% + 6px);
      position: absolute;
      top: -3px;
      left: 0;
      transform: translateX(-100%);
    }
  }
  
  &:not(:first-of-type):hover {
    filter: brightness(0.85);
    color: rgb(${(props) => props.theme.colors.YELLOW});
  }

  &:not(:nth-of-type(4n)) {
    border-right: 3px solid rgb(${(props) => props.theme.colors.WHITE});
  }

  &:before {
    content: '';
    position: absolute;
    width: 80%;
    height: 80%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: url(${LuckyToken});
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
    display: none;
  }

  &:nth-of-type(${(props) => props.active + 1}) {
    &:before {
      display: block;
    }
  }
`;

const WinnerWrapper = styled.div`
  background-color: rgb(${(props) => props.theme.colors.GREEN});
  padding: 60px 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  a {
    color: rgb(${(props) => props.theme.colors.YELLOW});
  }
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  svg {
    cursor: pointer;
    font-size: 1.3rem;

    &:hover {
      color: rgb(${(props) => props.theme.colors.YELLOW});
    }
  }
`;

const RouletteSpinner = styled.img`
  max-width: 400px;
  width: 100%;
`;

const BettingRounds = () => {
  const rounds = [1, 2, 3, 4, 5, 6, 7];
  const [firstGridValue, setFirstGridValue] = useRecoilState(firstGridState);
  const [secondGridValue, setSecondGridValue] = useRecoilState(secondGridState);
  const [colorValue, setColorValue] = useRecoilState(colorState);
  const [bets, setBets] = useRecoilState(betState);
  const accountId = useRecoilValue(accountIdState);
  const [result, setResult] = useRecoilState(winningNumberState);
  const [spinning, setSpinning] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [wager, setWager] = useState(0);
  const [winner, setWinner] = useRecoilState(winnerState);
  const [audio] = useState([new Audio(SpinningWav), new Audio(SpinStopWav), new Audio(ChipsWav), new Audio(WinWav)])
  const [possibleWin, setPossibleWin] = useState()
  const sound = useRecoilValue(audioState);


  useEffect(() => {
    setPossibleWin(bets[0] === '102' ? wager * 7 : (bets[0] === '100' || bets[0] === '101') ? wager * 2 : bets[0] === bets[0] ? wager * 77 : '')
  }, [setPossibleWin, bets, wager])

  // const winner = (parseInt(result[1]) <= 99 && result[0] === result[1]) || (parseInt(result[1]) === 100 && result[0] % 2 === 0 && result[0] % 11 !== 0) || (parseInt(result[1]) === 101 && result[0] % 2 !== 0 && result[0] % 11 !== 0) || (parseInt(result[1]) === 102 && result[0] % 11 === 0);

  const placeBet = useCallback((cost) => {
    audio[2].currentTime = 0;
    audio[2].play();
    setWager(cost)
    if (!(firstGridValue || secondGridValue) && !colorValue) return;
    setBets([firstGridValue || secondGridValue ? firstGridValue + secondGridValue : colorValue])
  }, [firstGridValue, secondGridValue, colorValue, setBets, setWager, audio])

  const spin = useCallback(async () => {
    const signer = await getSigner();
    setModalOpen(true);
    setSpinning(true);
    audio[0].currentTime = 0;
    audio[3].currentTime = 0;
    audio[0].play();
    const res = await spinTheWheel(accountId, bets, signer, wager);
    audio[0].pause();

    if (res[0]) {
      const nano = res[0] === '0' ? '00' : res[0] < 10 && res[0][0] !== '0' ? '0' + res[0] : res[0];
      const isWinner = (parseInt(res[1]) <= 99 && nano === res[1]) || (parseInt(res[1]) === 100 && nano % 2 === 0 && nano % 11 !== 0) || (parseInt(res[1]) === 101 && nano % 2 !== 0 && nano % 11 !== 0) || (parseInt(res[1]) === 102 && nano % 11 === 0);
      if (isWinner) {
        audio[3].play()
      } else {
        audio[1].play()
      }
      setResult([nano, res[1]]);
      setWinner(isWinner)
      setModalOpen(true)

    } else {
      setResult([res[0], res[1]])
    }

    setSpinning(false)
  }, [accountId, bets, wager, setResult, setSpinning, setModalOpen, setWinner, audio]);

  const reset = useCallback(() => {
    setBets([])
    setWager(0)
    setFirstGridValue('')
    setSecondGridValue('')
    setColorValue('')
  }, [setBets, setWager, setFirstGridValue, setSecondGridValue, setColorValue]);

  const CloseModal = useCallback((bool) => {
    setWinner(bool);
    setModalOpen(bool)
    audio[0].pause();
  }, [setWinner, setModalOpen, audio])

  useEffect(() => {
    if (!sound) {
      audio[0].muted = true;
      audio[1].muted = true;
      audio[2].muted = true;
      audio[3].muted = true;
    } else {
      audio[0].muted = false;
      audio[1].muted = false;
      audio[2].muted = false;
      audio[3].muted = false;
    }
  }, [sound, audio])

  useEffect(() => {
    reset()
  }, [reset])


  return (
    <BettingRoundsWrapper>
      <BettingRoundsContainer>
        <BettingRound>
          Bet
        </BettingRound>
        {rounds.map((round) => <BettingRound key={round} active={wager} onClick={() => placeBet(round)}>{round}</BettingRound>)}
      </BettingRoundsContainer>
      {!bets[0] ? <Typo.Sm>Choose a 2 digit number or a color</Typo.Sm> : wager ?
        <>
          {accountId ?
            <>
              <Button onClick={spin}>SPIN!</Button>
              <Buttons>
                <AiOutlineClose onClick={reset} />
                {bets[0] && <div>
                  <Typo.Md fontWeight="700"> Bet: {wager} $LUCKY on {bets[0] === '102' ? 'Green' : bets[0] === '100' ? 'Red' : bets[0] === '101' ? 'Black' : bets[0] === '0' ? '00' : bets[0] < 10 && bets[0][0] !== '0' ? '0' + bets[0] : bets[0]}</Typo.Md>
                  <Typo.Sm>Possible win: {possibleWin} $LUCKY</Typo.Sm>
                </div>}
              </Buttons>
            </>
            : <Pairing type="minimal" />}
        </> : <Typo.Sm>Choose an amount of $LUCKY</Typo.Sm>}

      {modalOpen &&
        <Modal handleModal={CloseModal} spinning={spinning}>
          {spinning && <RouletteSpinner src={RouletteSpin} alt="Spinning roulette" />}
          {!spinning &&
            <WinnerWrapper>
              {result[0] ?
                <>
                  {winner ?
                    <>
                      <Typo.H3 >YOU WIN!</Typo.H3>
                      <br />
                      <Typo.Lg textAlign="center">You bet {wager} $LUCKY on {result[1] === '102' ? 'green' : result[1] === '100' ? 'red' : result[1] === '101' ? 'black' : result[1]}. <br /> Result was {result[0]} and you win {possibleWin} $LUCKY tokens!</Typo.Lg>
                      <br />
                      <Typo.Md textAlign="center">WINNER WINNER CHICKEN DINNER!!</Typo.Md>
                    </> :
                    <>
                      <Typo.H3>SORRY!</Typo.H3>
                      <Typo.Lg textAlign="center">You bet {wager} $LUCKY on {result[1] === '102' ? 'green' : result[1] === '100' ? 'red' : result[1] === '101' ? 'black' : result[1]}, result was {result[0]}.</Typo.Lg>
                      <br />
                      <Typo.Md textAlign="center">Better luck next time!</Typo.Md>
                    </>
                  }
                </>
                :
                <>
                  <Typo.H3>FAILED!</Typo.H3>
                  <Typo.Lg textAlign="center" dangerouslySetInnerHTML={{ __html: result[1] }}></Typo.Lg>
                </>
              }
            </WinnerWrapper>
          }
        </Modal>
      }
    </BettingRoundsWrapper >
  )
}

export default BettingRounds;
