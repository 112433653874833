
const containers = {
  SCREEN_MD: 1170,
  SCREEN_SM: 970,
  SCREEN_MINI: 720
}

const breakpoints = {
  mobile: 445,
  tablet: 768,
  laptop: 1280,
};

function moreThan(maxKey) {
  return `@media (min-width: ${breakpoints[maxKey] - 1}px)`;
}

const media = {
  containers,
  breakpoints,
  moreThan
}


export default media;
