import styled, { keyframes } from 'styled-components';
import LuckyToken from '../../images/LuckyToken.svg';
import { FaTwitter, FaDiscord } from 'react-icons/fa'
import { useRecoilValue } from 'recoil';
import { accountIdState } from '../../atoms/globalAtoms';
import Modal from '../Modal';
import FeelingLucky from '../FeelingLucky';
import Pattern from '../../images/felt-pattern.jpg';
import { useState } from 'react';
import Typo from '../Typo';
import media from '../../theme/media';

const attention = keyframes`
  0% { transform: scale(1); }
  1% { transform: scale(1.1); }
  2%, 3% { transform: rotate(-20deg) scale(1.1); }
  4% { transform: rotate(20deg) scale(1.1); }
  5% { transform: rotate(0deg) scale(1); }
  100% { transform: scale(1); }
`;

const FooterWrapper = styled.footer`
  padding: 0 20px;
  background-color: rgb(${(props) => props.theme.colors.GREEN});
  position: relative;

  ${(media.moreThan('tablet'))} {
    padding: 0 40px;
  } 

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${Pattern});
    opacity: 0.1;
    pointer-events: none;
  }
`;

const FooterContainer = styled.div`
  max-width: ${(props) => props.theme.media.containers.SCREEN_MD}px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 20px 0;
  position: relative;
  img {
    width: 100px;
    cursor: pointer;
    animation: ${attention} 10s 5s infinite;
  }
`;

const Socials = styled.div`
  display: flex;
  gap: 20px;
  margin: 20px 0;

  a {
    transition: all .15s ease-out;
    color: rgb(${(props) => props.theme.colors.WHITE});
    &:hover {
      color: rgb(${(props) => props.theme.colors.YELLOW});
    }
  }

  svg {
    font-size: 2rem;
  }
`;

const Footer = () => {
  const year = new Date().getFullYear()
  const [modalOpen, setModalOpen] = useState(false);
  const accountId = useRecoilValue(accountIdState);

  return (
    <FooterWrapper>
      <FooterContainer>
        <img src={LuckyToken} alt="Lucky Logo" title={accountId && `Feeling $LUCKY? Click me!`} onClick={() => accountId && setModalOpen(true)} />
        <Socials>
          <a href="https://twitter.com/Lucky_HTS" target="_blank" rel="noreferrer"><FaTwitter /></a>
          <a href="https://discord.gg/bPDRZRce" target="_blank" rel="noreferrer"><FaDiscord /></a>
        </Socials>
        <Typo.Md>Copyright &copy; {year} LUCKY</Typo.Md>
        <Typo.Info textAlign="center">Lucky Token is an independent entity on the Hedera Hashgraph network, and is not responsible for any loss of Tokens that may occur.</Typo.Info>
        <Typo.Info textAlign="center">PLAY RESPONSIBLY.</Typo.Info>
      </FooterContainer>
      {modalOpen && <Modal handleModal={setModalOpen}>
        <FeelingLucky />
      </Modal>}
    </FooterWrapper>
  )
}

export default Footer;
