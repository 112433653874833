import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { accountIdState } from '../../atoms/globalAtoms';
import Pairing from '../Pairing';
import Typo from '../Typo';

const BalancePopupWrapper = styled.div`
  background-color: rgb(${(props) => props.theme.colors.GREEN});
  padding: 90px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 30px;
  
  p:first-of-type {
    margin: 20px 0;
    text-align: center;
  }
`;

const BalancePopup = () => {
  const accountId = useRecoilValue(accountIdState);
  return (
    <BalancePopupWrapper>
      <div>
        <Typo.H4 marginBottom="20px">Paired Wallet</Typo.H4>
        <Typo.Md>{accountId}</Typo.Md>
      </div>
      <Pairing type="minimal" />
    </BalancePopupWrapper>
  )
}

export default BalancePopup;
