import { useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { accountIdState, accountBalanceState } from './atoms/globalAtoms';
import Footer from './components/Footer';
import Header from './components/Header';
import GetLucky from './pages/GetLucky';
import GreenPaper from './pages/GreenPaper';
import Home from './pages/Home';
import Roulette from './pages/Roulette';
import { getBalance } from './utils/hedera';
import { pairedAccountId, hcInitPromise } from './utils/hashconnect.ts';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CookieConsent from "react-cookie-consent";

import ReactGA from 'react-ga4';
ReactGA.initialize('G-X2QE9WXYBS');

const RequireAuth = ({ accountId, children }) => {
  setTimeout(() => {
    if (!accountId) {
      return <Navigate to="/" replace />
    }
  }, 2000);
  return children;
}

const App = () => {
  const setBalance = useSetRecoilState(accountBalanceState);
  const [accountId, setAccountId] = useRecoilState(accountIdState);
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      async function fetchBalance() {
        const accBalance = await getBalance(accountId)
        setBalance(accBalance)
      }
      fetchBalance()
    }, 1000)
  }, [accountId, setBalance])

  useEffect(() => {
    hcInitPromise.then(() => {
      setAccountId(pairedAccountId());
    });
  }, [setAccountId]);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location])

  return (
    <>
      <Header />
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/greenpaper" element={<GreenPaper />} />
        <Route path="/get-lucky" element={<GetLucky />} />
        <Route path="/roulette" element={<Roulette />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <Footer />
      <ToastContainer
        position="bottom-right"
      />
      <CookieConsent
        enableDeclineButton
        location="bottom"
        buttonText="Ok!"
        cookieName="luckycookies"
        style={{ background: "#417c25" }}
        buttonStyle={{ background: "#f4da40", color: "#417c25", fontSize: "13px", fontWeight: 'bold' }}
        expires={150}>
        This website uses third party cookies to enhance the user experience and analyze web traffic.
      </CookieConsent>
    </>
  );
}

export default App;
