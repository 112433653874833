
import { Helmet } from 'react-helmet-async';
import Section from '../components/Section';
import Typo from '../components/Typo';
import { SeperatorDown, SeperatorUp } from '../components/Seperator';
import Pairing from '../components/Pairing';
import Button from '../components/Button';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { accountIdState } from '../atoms/globalAtoms';
import styled from 'styled-components';

const Center = styled.div`
  display: flex;
  justify-content: center;
`;

const Home = () => {
  const navigate = useNavigate();
  const accountId = useRecoilValue(accountIdState)

  return (
    <main>
      <Helmet>
        <title>Lucky Token | Home</title>
      </Helmet>
      <Section>
        <SeperatorUp />
        <Typo.H1>LUCKY</Typo.H1>

        <Typo.Xl marginBottom="20px" textAlign="center">Welcome to Lucky HTS!</Typo.Xl>
        <Typo.Md transform="uppercase" textAlign="center">The home of gaming and gambling on the Hedera Network.</Typo.Md>
        <Typo.Md transform="uppercase" textAlign="center">PROVABLY FAIR. NOMINAL TRANSFER FEES. INSTANT PAYOUTS.</Typo.Md>
      </Section>
      <br />
      {accountId ?
        <Center>
          <Button onClick={() => navigate("/roulette")} >Play roulette!</Button>
        </Center>
        :
        <Typo.H2> </Typo.H2>
      }
      <Section>
        <Pairing />
        <SeperatorDown />
      </Section>
    </main>
  )
}

export default Home;
