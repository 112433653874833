import { Helmet } from 'react-helmet-async';
import Section from '../components/Section';
import Typo from '../components/Typo';
import GreenPaperP1 from '../images/GREENPAPER-1.jpg';
import GreenPaperP2 from '../images/GREENPAPER-2.jpg';
import GreenPaperP3 from '../images/GREENPAPER-3.jpg';
import GreenPaperP4 from '../images/GREENPAPER-4.jpg';
import styled from 'styled-components';
import { SeperatorDown, SeperatorUp } from '../components/Seperator';

const ImageWrapper = styled.div`
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  img {
    width: 100%;
  }
`;

const GreenPaper = () => {
  return (
    <main>
      <Helmet>
        <title>Lucky Token | Greenpaper</title>
      </Helmet>
      <Section>
        <SeperatorUp />
        <Typo.H1>Greenpaper</Typo.H1>
        <ImageWrapper>
          <Typo.BigLink download href={process.env.PUBLIC_URL + "/LUCKY-TOKEN-GREENPAPER.pdf"} target="_blank" rel="norefferer">Download PDF</Typo.BigLink>
          <img loading='lazy' src={GreenPaperP1} alt="Greenpaper page 1" />
          <img loading='lazy' src={GreenPaperP2} alt="Greenpaper page 2" />
          <img loading='lazy' src={GreenPaperP3} alt="Greenpaper page 3" />
          <img loading='lazy' src={GreenPaperP4} alt="Greenpaper page 4" />
        </ImageWrapper>
        <SeperatorDown />
      </Section>
    </main>
  )
}

export default GreenPaper;
