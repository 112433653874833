import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Navigation from '../Navigation';
import LuckyTokenLogo from '../../images/LuckyTokenLogo.svg';
import Pattern from '../../images/felt-pattern.jpg';
import Balance from '../Balance';
import media from '../../theme/media';

const HeaderWrapper = styled.header`
  padding: 0 20px;
  background-color: rgb(${(props) => props.theme.colors.GREEN});
  position: relative;

  ${(media.moreThan('tablet'))} {
    padding: 0 40px;
  } 

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${Pattern});
    opacity: 0.1;
    pointer-events: none;
  }
`;

const HeaderContainer = styled.div`
  max-width: ${(props) => props.theme.media.containers.SCREEN_MD}px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  position: relative;
  z-index: 99;

  a {
    display: flex;
    align-items: center;
  }
`;

const Logo = styled.img`
  width: 100%;
  max-width: 200px;
  min-width: 150px;
  margin-top: 5px;
  padding-right: 20px;
`;

const StyledLink = styled(Link)`
  margin-right: auto;

  ${(media.moreThan('tablet'))} {
    margin-right: 0;
  } 
`;

const BalanceWrapper = styled.div`
  order: 0;  
  margin-right: 20px;
  max-width: 200px;
  width: 100%;
  
  button {
    margin-right: 40px;

    ${(media.moreThan('mobile'))} {
      display: block;
    }
  }
  
  ${(media.moreThan('tablet'))} {
    margin-right: 0;
    order: 1;
  }

`;

const Header = () => {
  return (
    <HeaderWrapper>
      <HeaderContainer>
        <StyledLink to="/"><Logo src={LuckyTokenLogo} alt="Lucky Logo" /></StyledLink>
        <Navigation />
        <BalanceWrapper>
          <Balance />
        </BalanceWrapper>
      </HeaderContainer>
    </HeaderWrapper>
  )
}

export default Header;
