import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useRecoilState } from 'recoil';
import { accountIdState } from '../../atoms/globalAtoms';
import Modal from '../Modal';
import { hashconnect } from '../../utils/hashconnect.ts';
import Typo from '../Typo';
import Button from '../Button';
import PairingPopup from './PairingPopup';

const Center = styled.div`
  margin-top: ${(props) => props.type !== 'minimal' ? '30px' : '0'};
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  a {
    color: rgb(${(props) => props.theme.colors.WHITE});
    text-decoration: underline;
    transition: all .15s ease-out;

    &:hover {
      color: rgb(${(props) => props.theme.colors.YELLOW});
    }
  }
`;

const Pairing = ({ type }) => {
  const [modal, setModal] = useState(false);
  const [accountId, setAccountId] = useRecoilState(accountIdState);

  const disconnect = useCallback(() => {
    hashconnect.disconnect();
    setAccountId('');
  }, [setAccountId]);

  return (
    <>
      {type !== 'minimal' && <Typo.Lg marginBottom="40px" textAlign="center">{!accountId ? 'To start off, pair your wallet.' : 'You have paired your wallet. Go on and play some games!'}</Typo.Lg>}
      <Center type={type}>
        {accountId ? (
          <>
            {type !== 'minimal' && <Typo.Md textAlign="center">Connected as: {accountId}</Typo.Md>}
            <Button type={type !== 'minimal' ? "red" : "reddark"} size={type} onClick={disconnect}>Disconnect</Button>
          </>

        ) : (
          <>
            <Button size={type} onClick={() => setModal(true)}>Connect wallet</Button>
            {type !== 'minimal' && <Typo.Md><a href="https://hashpack.app" target="_blank" rel="noreferrer">You need HashPack to play!</a></Typo.Md>}
          </>
        )}
      </Center>

      {modal &&
        <Modal handleModal={setModal}>
          <PairingPopup />
        </Modal>}
    </>
  );
}

export default Pairing;
