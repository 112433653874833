import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { accountIdState } from '../../atoms/globalAtoms';
import { getSigner } from '../../utils/hashconnect.ts';
import { sendLucky } from '../../utils/hedera';
import Button from '../Button';
import Typo from '../Typo';

const FeelingLuckyWrapper = styled.div`
  background-color: rgb(${(props) => props.theme.colors.GREEN});
  padding: 90px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  // gap: 10px;
  width: 100%;
  p:first-of-type {
    margin-bottom: 20px;
    text-align: center;
  }

  button {
    margin-top: 40px;
  }
`;

const FeelingLucky = () => {
  const accountId = useRecoilValue(accountIdState);

  const EnterGame = useCallback(async () => {
    const signer = await getSigner();
    sendLucky(accountId, signer)
  }, [accountId])

  return (
    <FeelingLuckyWrapper>
      <Typo.H4>Feeling $LUCKY?</Typo.H4>
      <Typo.Lg>Win 77 $LUCKY Tokens!</Typo.Lg>
      <Typo.Md textAlign="center">1 winner for every 88 entries.</Typo.Md>
      <Typo.Md>How does this work?</Typo.Md>
      <br />
      <Typo.Md textAlign="center">Simple: Just send 1 $LUCKY and you have a chance to win.</Typo.Md>
      <Button type="basic" onClick={EnterGame}>Send 1 $LUCKY</Button>
    </FeelingLuckyWrapper>
  )
}

export default FeelingLucky;
