import styled from 'styled-components';
import media from '../../theme/media';
import AccordionItem from './AccordionItem';

const AccordionWrapper = styled.div`
  margin: 60px auto 0;
  max-width: 700px;

  ${(media.moreThan('tablet'))} {
    margin: 30px auto 0;
  }
`;

const Accordion = ({ items }) => {
  return (
    <AccordionWrapper>
      {items?.map((item, i) => <AccordionItem key={i} title={item.title} content={item.content} />)}
    </AccordionWrapper>
  )
}

export default Accordion;
