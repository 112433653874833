import { useState } from 'react';
import styled from 'styled-components';
import Typo from '../Typo';
import theme from '../../theme';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';
import Pattern from '../../images/felt-pattern.jpg';


const AccordionItemWrapper = styled.div`
  cursor: pointer;
  width: 100%;
  border: 3px solid rgb(${(props) => props.theme.colors.YELLOW});
  
  &:not(:last-of-type) {
    margin-bottom: 20px;
  }
`;

const AccordionTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(${(props) => props.theme.colors.GREEN});
  color: rgb(${(props) => props.theme.colors.YELLOW});
  padding: 20px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${Pattern});
    opacity: 0.1;
    pointer-events: none;
  }

  svg {
    font-size: 2rem;
  }
`;

const AccordionContent = styled.div`
  background-color: rgb(${(props) => props.theme.colors.BLACK});
  border-top: 3px solid rgb(${(props) => props.theme.colors.YELLOW});
  padding: 20px;
  width: 100%;
  line-height: 1.6;
  font-size: 1.3rem;
  
  ul {
    margin: 0;
    padding-left: 20px;
    li:not(:last-of-type) {
      margin-bottom: 10px;
    }
  }
`;

const AccordionItem = ({ title, content }) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <AccordionItemWrapper onClick={() => setOpen(!isOpen)}>
      <AccordionTitle>
        <Typo.H5 textAlign="left" color={theme.colors.YELLOW} fontWeight="700">
          {title}
        </Typo.H5>
        {isOpen ? <AiOutlineMinus /> : <AiOutlinePlus />}
      </AccordionTitle>
      {isOpen && <AccordionContent dangerouslySetInnerHTML={{ __html: content }}></AccordionContent>}
    </AccordionItemWrapper>
  )
}

export default AccordionItem;
