import React from 'react';
import styled from 'styled-components';
import { GRID_SIZE } from '../../constants/grid';
import LuckyToken from '../../images/LuckyToken.svg';
import media from '../../theme/media';

const RouletteNumberWrapper = styled.div`
  border-bottom: 3px solid rgb(${(props) => props.theme.colors.WHITE});
  cursor: pointer;
  position: relative;
  transition: all .15s ease-in;
  width: ${GRID_SIZE}px;
  height: ${GRID_SIZE}px;
  display: grid;
  place-items: center;

  ${(media.moreThan('tablet'))} {
    border-right: 3px solid rgb(${(props) => props.theme.colors.WHITE});
  }

  &:hover {
    filter: brightness(0.85);
  }

  &:before {
    content: '';
    position: absolute;
    width: 80%;
    height: 80%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: url(${LuckyToken});
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
    display: none;
    z-index: 2;
  }
`;

const Number = styled.p`
  font-family: ${(props) => props.theme.fonts.types.SECONDARY};
  font-size: 2rem;
  font-weight: 700;
  line-height: 1;
  margin: 0;
  color: rgb(${(props) => props.theme.colors.WHITE});
`;

const RouletteNumber = ({ label, ...props }) => {
  return (
    <RouletteNumberWrapper onClick={props.onClick}><Number>{label}</Number></RouletteNumberWrapper>
  )
}

export default RouletteNumber;
