import styled from 'styled-components';
import LineShape from '../../images/LineShape.svg';

export const SeperatorUp = styled.div`
    max-width: 390px;
    width: 90%;
    height: 25px;
    background-image: url(${LineShape});
    background-repeat: no-repeat;
    background-size: contain;
    transform: rotate(180deg);
    margin: 0 auto 60px;
`;

export const SeperatorDown = styled.div`
    max-width: 390px;
    width: 90%;
    height: 25px;
    background-image: url(${LineShape});
    background-repeat: no-repeat;
    background-size: contain;
    margin: 60px auto 0;
`;
