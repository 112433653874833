import { atom } from 'recoil';

export const accountIdState = atom({
  key: 'accountIdState',
  default: null
});

export const modalState = atom({
  key: 'modalState',
  default: false
});

export const accountBalanceState = atom({
  key: 'accountbalanceState',
  default: 0
});

export const hamburgerState = atom({
  key: 'hamburgerState',
  default: false
});

export const audioState = atom({
  key: 'audioState',
  default: true
});

