import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import { betState, colorState, firstGridState, secondGridState } from '../../atoms/rouletteAtom';
import { GRID_SIZE } from '../../constants/grid';
import media from '../../theme/media';
import RouletteColor from './RouletteColor';
import RouletteItem from './RouletteNumber';
import ChipsWav from '../../sounds/chip.mp3';
import { audioState } from '../../atoms/globalAtoms';

const RouletteWrapper = styled.div`
  display: flex;
  
  ${(media.moreThan('tablet'))} {
    flex-direction: column-reverse;
  }
`;

const RouletteColorsWrapper = styled.div`
  margin-top: ${GRID_SIZE}px;

  ${(media.moreThan('tablet'))} {
    margin: 0 0 0 ${GRID_SIZE}px;
    display: flex;
  }
`;

const RouletteGridWrapper = styled.div`
  display: inline-block;
  border: 3px solid rgb(${(props) => props.theme.colors.WHITE});

  ${(media.moreThan('tablet'))} {
    display: flex; 
    border-top: 3px solid rgb(${(props) => props.theme.colors.WHITE});
    border-left: 3px solid rgb(${(props) => props.theme.colors.WHITE});
    border-right: none;
    border-bottom: none;
    max-width: 553px;
    margin-right: 55px;
  }
`;

const RouletteGridColumns = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  ${(media.moreThan('tablet'))} {
    flex-direction: column;
    flex-wrap: wrap;
  }
`;

const RouletteGridColumn = styled.div`
  display: inline-grid;
  grid-template-columns: repeat(1, 1fr);  

  ${(media.moreThan('tablet'))} {
    grid-template-columns: repeat(10, 1fr);  
  }

  &:first-of-type {
    border-right: 3px solid rgb(${(props) => props.theme.colors.WHITE});

    ${(media.moreThan('tablet'))} {
      border-right: none;
    }

    div:nth-of-type(${(props) => props.firstGrid === 0 ? 10 : props.firstGrid}) {
      &:before {
        display: block;
      }
    }

    div:nth-of-type(odd) {
      background-color: rgb(${(props) => props.theme.colors.BLACK});
    }

    div:nth-of-type(even) {
      background-color: rgb(${(props) => props.theme.colors.RED});
    }
  }

  &:last-of-type {
    div:nth-last-of-type(${(props) => props.secondGrid === 0 ? 10 : props.secondGrid}) {
      &:before {
        display: block;
      }
    }

    div:nth-of-type(odd) {
      background-color: rgb(${(props) => props.theme.colors.RED});
    }

    div:nth-of-type(even) {
      background-color: rgb(${(props) => props.theme.colors.BLACK});
    }
  }
`;

const BottomBox = styled.div`
  display: grid;
  place-items: center;
  font-size: 1.7rem;
  font-weight: 700;
  height: ${GRID_SIZE}px;
  line-height: 1;
  background-color: rgb(${(props) => props.theme.colors.GREEN});
  color: rgb(${(props) => props.theme.colors.WHITE});
  font-family: ${(props) => props.theme.fonts.types.SECONDARY};

  
  ${(media.moreThan('tablet'))} {
    border: 3px solid rgb(${(props) => props.theme.colors.WHITE});
    border-left: none;
    height: ${GRID_SIZE * 2 + 3}px;
    margin-top: -3px;
    padding: 0 10px;
    text-align: center;
  }
`;

const RouletteBoard = () => {
  const RouletteNumbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
  const RouletteColors = [
    { value: '102', label: '7 to 1' },
    { value: '100', label: '2 to 1' },
    { value: '101', label: '2 to 1' }
  ];
  const [firstGridValue, setFirstGridValue] = useRecoilState(firstGridState);
  const [secondGridValue, setSecondGridValue] = useRecoilState(secondGridState);
  const [colorValue, setColorValue] = useRecoilState(colorState);
  const setBets = useSetRecoilState(betState);
  const [audio] = useState(new Audio(ChipsWav))
  const sound = useRecoilValue(audioState);

  useEffect(() => {
    if (!sound) {
      audio.muted = true;
    } else {
      audio.muted = false;
    }
  }, [sound, audio])

  const handleFirstNumber = useCallback((val) => {
    audio.currentTime = 0;
    audio.play()
    if (firstGridValue === val) {
      setFirstGridValue('')
      setBets(['' + secondGridValue])
    } else {
      //Empty color values and remove marker
      setColorValue('')
      setFirstGridValue(val.toString())
      setBets([val.toString() + secondGridValue])
    }
  }, [firstGridValue, secondGridValue, setFirstGridValue, setColorValue, setBets, audio])

  const handleSecondNumber = useCallback((val) => {
    audio.currentTime = 0;
    audio.play()
    if (secondGridValue === val) {
      setSecondGridValue('')
      setBets([firstGridValue + ''])
    } else {
      //Empty color values and remove marker
      setColorValue('')
      setSecondGridValue(val.toString())
      setBets([firstGridValue + val.toString()])
    }
  }, [secondGridValue, firstGridValue, setSecondGridValue, setColorValue, setBets, audio])

  const handleColorValue = useCallback((val) => {
    audio.currentTime = 0;
    audio.play()
    if (colorValue === val.value) {
      setColorValue('')
    } else {
      //Empty grid values and remove markers
      setFirstGridValue('')
      setSecondGridValue('')
      setColorValue(val.value)
      setBets([val.value])
    }
  }, [colorValue, setFirstGridValue, setColorValue, setSecondGridValue, setBets, audio])

  return (
    <RouletteWrapper>
      <RouletteColorsWrapper>
        {RouletteColors.map((color) => <RouletteColor onClick={() => handleColorValue(color)} key={color.value} color={color.value} label={color.label} active={RouletteColors.map((color) => color.value).indexOf(colorValue) + 1} />)}
      </RouletteColorsWrapper>
      <RouletteGridWrapper>
        <RouletteGridColumns>
          <RouletteGridColumn firstGrid={parseInt(firstGridValue)}>
            {RouletteNumbers.map((number, i) => <RouletteItem onClick={() => handleFirstNumber(number)} key={i} label={number} />)}
          </RouletteGridColumn>
          <RouletteGridColumn secondGrid={parseInt(secondGridValue)}>
            {RouletteNumbers.reverse().map((number, i) => <RouletteItem onClick={() => handleSecondNumber(number)} key={i} label={number} />)}
          </RouletteGridColumn>
        </RouletteGridColumns>
        <BottomBox>
          77 to 1
        </BottomBox>
      </RouletteGridWrapper>
    </RouletteWrapper>
  )
}

export default RouletteBoard;
