import styled, { css } from 'styled-components';
import ButtonEndCap from '../../images/ButtonEndCap.svg';
import ButtonLines from '../../images/ButtonLines.svg';
import ButtonMiddle from '../../images/ButtonMiddle.svg';

const StyledButton = styled.button`
  border: none;
  // border-left: none;
  // border-right: none;
  // border: 3px solid rgb(${(props) => props.type === 'basic' ? props.theme.colors.WHITE : props.theme.colors.YELLOW});
  padding: ${(props) => props.size === 'minimal' ? '16.5px 5px' : '13px 5px'};
  // color: rgb(${(props) => props.type === 'basic' ? props.theme.colors.WHITE : props.type === 'green' ? props.theme.colors.GREEN : props.type === 'red' ? props.theme.colors.RED : props.theme.colors.YELLOW});
  cursor: pointer;
  font-size: ${(props) => props.size !== 'minimal' ? '1.2rem' : '0.8rem'};
  font-weight: 700;
  text-transform: uppercase;
  font-family: ${(props) => props.theme.fonts.types.SECONDARY};
  position: relative;
  z-index: 1;
  background-color: transparent;
  white-space: nowrap;
  transition: all .15s ease-out;

  ${(props) =>
    props.type === 'basic' ?
      css`
        color: rgb(${(props) => props.theme.colors.WHITE});

        &:hover {
          color: rgb(${(props) => props.theme.colors.YELLOW});
        }
      ` :
      props.type === 'green' ?
        css`
        color: rgb(${(props) => props.theme.colors.GREEN_LIGHT});

        &:hover {
          color: rgb(${(props) => props.theme.colors.YELLOW});
        }
      ` :
        props.type === 'red' ?
          css`
        color: rgb(${(props) => props.theme.colors.RED});

        &:hover {
          color: rgb(${(props) => props.theme.colors.YELLOW});
        }
      ` :
          props.type === 'reddark' ?
            css`
        color: rgb(${(props) => props.theme.colors.RED_DARK});

        &:hover {
          color: rgb(${(props) => props.theme.colors.YELLOW});
        }
      ` :
            css`
        color: rgb(${(props) => props.theme.colors.YELLOW});

        &:hover {
          color: rgb(${(props) => props.theme.colors.GREEN_LIGHT});
        }
      `}

  &:before, &:after {
      content: '';
      position: absolute;
      left: 50%;
      width: 46px;
      height: 25px;
      z-index: -1;
      background-image: url(${ButtonMiddle});
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }

    &:before {
      top: -12px;
      transform: translateX(-50%) rotate(180deg);
    }

    &:after {
      bottom: -13px;
      transform: translateX(-50%);
    }
`;

const TopBorder = styled.i`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;

  &:before, &:after {
    content: '';
    position: absolute;
    width: calc(50% - 20px);
    top: -1px;
    height: 100%;
    background-image: url(${ButtonLines});
    background-position: center;
    background-repeat: repeat;
    background-size: contain;
  }

  &:before {
    left: -2px;
  }

  &:after {
    right: -2px;
  }
`;

const BottomBorder = styled.i`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;

  &:before, &:after {
    content: '';
    position: absolute;
    width: calc(50% - 20px);
    bottom: -1px;
    transform: rotate(180deg);
    height: 100%;
    background-image: url(${ButtonLines});
    background-position: center;
    background-repeat: repeat;
    background-size: contain;
  }

  &:before {
    left: -2px;
  }

  &:after {
    right: -2px;
  }
`;

const LeftBorder = styled.i`
  position: absolute;
  top: -1px;
  left: 0;
  width: 60px;
  height: 50px;
  transform: translateX(-100%);
  background-image: url(${ButtonEndCap});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`;

const RightBorder = styled.i`
  position: absolute;
  top: -1px;
  right: 0;
  width: 60px;
  height: 50px;
  transform: translateX(100%) rotate(180deg);
  background-image: url(${ButtonEndCap});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`;



const Button = ({ type, size, ...props }) => {
  return (
    <StyledButton type={type} size={size} onClick={props.onClick}>
      <TopBorder />
      <RightBorder />
      {props.children}
      <LeftBorder />
      <BottomBorder />
    </StyledButton>
  )
}

export default Button;
