import styled from 'styled-components';
import media from '../../theme/media';
import Typo from '../Typo';


const StyledSection = styled.section`
  padding: 0 20px;
  position: relative;

  ${(media.moreThan('tablet'))} {
    padding: 0 40px;
  } 
`;

const Container = styled.div`
  padding: 80px 0;
  width: 100%;
  max-width: ${(props) => props.theme.media.containers.SCREEN_MD + 'px'};
  margin: 0 auto;
  position: relative;
`;


const Section = ({ children, title, refContent, id }) => {
  return (
    <StyledSection id={id} ref={refContent}>
      <Container>
        {title && <Typo.H2 marginBottom="30px">{title}</Typo.H2>}
        {children}
      </Container>
    </StyledSection >
  )
}

export default Section;
