import React, { useCallback, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useRecoilState, useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { winnerState } from '../atoms/rouletteAtom';
import Accordion from '../components/Accordion';
import ConfettiRain from '../components/ConfettiRain';
import RouletteBoard from '../components/RouletteBoard';
import BettingRounds from '../components/RouletteBoard/BettingRounds';
import Section from '../components/Section';
import { SeperatorDown, SeperatorUp } from '../components/Seperator';
import Typo from '../components/Typo';
import media from '../theme/media';
import { FaVolumeMute, FaVolumeUp, FaRegQuestionCircle } from 'react-icons/fa';
import { audioState } from '../atoms/globalAtoms';

const ContainerGroup = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  margin-top: 30px;
  gap: 40px;

  ${(media.moreThan('tablet'))} {
    justify-content: center;
    flex-direction: row;
    align-items: flex-start;
    gap: 20px;
  }
`;


const TopBar = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 30px;
  
  ${(media.moreThan('tablet'))} {
    margin-bottom: 0;
    justify-content: flex-end;
  }
`;

const Icon = styled.i`
  cursor: pointer;

  &:not(:last-of-type) {
    margin-right: 20px;
  }

  svg {
    font-size: 2rem;
  }

  &:last-of-type {
    color: rgb(${(props) => props.theme.colors.YELLOW});
  }
`;

const Faq = [
  { title: 'HOW TO PLAY', content: "<ul><li>Place a LUCKY Token on a color or make it a number between 00-99.</li><li>Select a bet amount of 1-7 $LUCKY Tokens. If you place a bet by mistake or change your mind you may press the [x] next to the betting parameter dialogue below the SPIN button.</li><li>When you're ready to spin the roulette wheel press the SPIN button and approve the transaction in HashPack.</li><li>Wins are paid out within moments of transaction finality.</li></ul>" },
  { title: 'RULES', content: "<ul><li>Even numbers win only on RED: 02, 04, 06, 08...96, 98 <br/> Payout is 2 to 1.</li> <li>Odd numbers win only on BLACK: 01, 03, 05, 07...95, 97 <br/> Payout is 2 to 1.</li> <li>Doubles win only on GREEN: 00, 11, 22, 33...88, 99 <br/> Payout is 7 to 1</li><li>EXACT number wins on EXACT MATCH ONLY. (You play 37, winning number is 37) <br/> Payout is 77 to 1.</li><li>RED AND BLACK DO NOT WIN ON DOUBLES</li</ul>" },
  { title: 'MECHANICS', content: "<ul><li>LUCKY Token Roulette derives a win or loss outcome based on the timestamp of the submitted bet. The timestamp is deterministically shuffled, to provide a provably fair and random experience.</li><li>If your bets correspond with a win, then you win! Whether you win or lose ultimately comes down to when you press the approve transaction button.</li></ul>" },
];

const Roulette = () => {
  const winner = useRecoilValue(winnerState);
  const [sound, setSound] = useRecoilState(audioState);
  const FaqRef = useRef(null);

  const scrollTo = useCallback((ref) => {
    ref.current?.scrollIntoView();
  }, [])


  return (
    <main>
      <Helmet>
        <title>Lucky Token | Roulette</title>
      </Helmet>

      <ConfettiRain isActive={winner} />

      <Section>
        <TopBar>
          <div>
            <Icon onClick={() => setSound(!sound)}>
              {sound ? <FaVolumeUp /> : <FaVolumeMute />}
            </Icon>
            <Icon onClick={() => scrollTo(FaqRef)}>
              <FaRegQuestionCircle />
            </Icon>
          </div>
        </TopBar>
        <SeperatorUp />
        <Typo.H1>Roulette</Typo.H1>
        <ContainerGroup>
          <RouletteBoard />
          <BettingRounds />
        </ContainerGroup>
      </Section>
      <Section title="FAQ" refContent={FaqRef} id="faq">
        <Accordion items={Faq} />
        <SeperatorDown />
      </Section>
    </main>
  )
}

export default Roulette;
