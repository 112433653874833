import React from 'react';
import styled from 'styled-components';
import { GRID_SIZE } from '../../constants/grid';
import LuckyToken from '../../images/LuckyToken.svg';
import media from '../../theme/media';

const Wrapper = styled.div`
  display: flex;

  ${(media.moreThan('tablet'))} {
    flex-direction: column-reverse;
    border-right: 3px solid rgb(${(props) => props.theme.colors.WHITE});

    &:first-of-type {
      border-left: 3px solid rgb(${(props) => props.theme.colors.WHITE});
    }
  }

  &:nth-of-type(${(props) => props.active}) div {
    &:before {
      display: block;
    }
  }

  &:last-of-type {
    border-bottom: 3px solid rgb(${(props) => props.theme.colors.WHITE});

    ${(media.moreThan('tablet'))} {
      flex-direction: column-reverse;
      border-bottom: none;
    }
  }
`;

const RouletteColorWrapper = styled.div`
  border-top: 3px solid rgb(${(props) => props.theme.colors.WHITE});
  border-left: 3px solid rgb(${(props) => props.theme.colors.WHITE});
  cursor: pointer;
  position: relative;
  transition: all .15s ease-in;
  width: ${GRID_SIZE}px;
  height: calc(${GRID_SIZE}px * 3);
  display: grid;
  place-items: center;
  background-color: rgb(${(props) => props.theme.colors.GREEN});
  color: rgb(${(props) => props.theme.colors.WHITE});

  ${(media.moreThan('tablet'))} {
    border: none;
    width: calc(${GRID_SIZE - 1}px * 3);
    height: ${GRID_SIZE}px;
  }

  &:hover {
    filter: brightness(0.85);
  }

  &:before {
    content: '';
    position: absolute;
    width: 90%;
    height: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: url(${LuckyToken});
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
    display: none;
    z-index: 3;
  }

  svg {
    height: 200%;
    transform: rotate(90deg);
    position: absolute;
  }
`;

const Color = styled.div`
  width: 70%;
  height: 100%;
  margin: 0;
  background-color: rgb(${(props) => props.color === '102' ? props.theme.colors.GREEN_DARK : props.color === '100' ? props.theme.colors.RED : props.theme.colors.BLACK});
  clip-path: polygon(50% 20%, 100% 50%, 50% 80%, 0% 50%);
  position: relative;
  z-index: 1;

  ${(media.moreThan('tablet'))} {
    width: 75%;
  }
`;

const Diamond = styled.div`
  position: absolute;
  clip-path: polygon(50% 20%, 100% 50%, 50% 80%, 0% 50%);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(70% + 6px);
  height: calc(100% + 24px);
  background-color: rgb(${(props) => props.theme.colors.WHITE});

  ${(media.moreThan('tablet'))} {
    width: calc(75% + 24px);
    height: calc(100% + 12px);
  }
  `

const Text = styled.span`
  font-family: ${(props) => props.theme.fonts.types.SECONDARY};
  width: ${GRID_SIZE}px;
  height: calc(${GRID_SIZE}px * 3);
  border-top: 3px solid rgb(${(props) => props.theme.colors.WHITE});
  border-left: 3px solid rgb(${(props) => props.theme.colors.WHITE});
  position: relative; 
  display: grid;
  place-items: center;
  background-color: rgb(${(props) => props.theme.colors.GREEN});
  color: rgb(${(props) => props.theme.colors.WHITE});

  ${(media.moreThan('tablet'))} {
    width: calc(${GRID_SIZE - 1}px * 3);
    height: ${GRID_SIZE}px;
    border-left: none;
    border-top: 3px solid rgb(${(props) => props.theme.colors.WHITE});
    border-bottom: 3px solid rgb(${(props) => props.theme.colors.WHITE});
  }

  p {
    font-size: 2rem;
    font-weight: 700;
    white-space: nowrap;
    line-height: 0;
    margin: 0;
    transform: rotate(90deg);
    position: absolute;

    ${(media.moreThan('tablet'))} {
      transform: rotate(0);
    }
  }
`;

const RouletteColor = ({ color, active, label, ...props }) => {
  return (
    <Wrapper active={active}>
      <Text><p>{label}</p></Text>
      <RouletteColorWrapper onClick={props.onClick} >
        <Color color={color} />
        <Diamond />
      </RouletteColorWrapper>
    </Wrapper>
  )
}

export default RouletteColor;
