import { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import Button from '../Button';
import Typo from '../Typo';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { hashconnect } from '../../utils/hashconnect.ts';
import { useSetRecoilState } from 'recoil';
import { accountIdState } from '../../atoms/globalAtoms';
import { useNavigate } from 'react-router-dom';

const PairingPopupWrapper = styled.div`
  background-color: rgb(${(props) => props.theme.colors.GREEN});
  padding: 90px 30px;

  display: flex;
  flex-direction: column;
  max-width: 550px;
  width: 100%;
  gap: 40px;
  align-items: center;

  p {
    margin-bottom: 10px;
  }
`;

const Clear = styled(Typo.Sm)`
  cursor: pointer;
  text-decoration: underline;
  text-align: center;
`

const PairingPopup = () => {
  const navigate = useNavigate();
  const setAccountId = useSetRecoilState(accountIdState);

  const connect = useCallback(() => {
    hashconnect.connectToLocalWallet();
  }, []);

  const copyPairing = () => {
    navigator.clipboard.writeText(hashconnect.hcData.pairingString)
    toast.success("Pairing string copied!");
  }

  const clearCache = () => {
    localStorage.removeItem('hashconnectData');
  }

  useEffect(() => {
    hashconnect.pairingEvent.on((data) => {
      setAccountId(data.accountIds[0]);
      navigate("/roulette");
    });
  }, [setAccountId, navigate])

  return (
    <PairingPopupWrapper>
      <div>
        <Typo.H4 marginBottom="10px">Connect your wallet</Typo.H4>
        <Typo.Sm textAlign="center">Sign into your wallet and select the account you wish to use. <br /> If you are on mobile, copy the pairing string and paste it in Hashpack.</Typo.Sm>
      </div>
      <Button type="basic" onClick={connect}>Connect with Hashpack</Button>
      <Button type="basic" onClick={copyPairing}>Copy Pairing String</Button>
      <Clear onClick={clearCache}>Having problems pairing? <br /> Click here to clear your cache.</Clear>
    </PairingPopupWrapper>
  )

}

export default PairingPopup;
